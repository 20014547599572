/* eslint-disable no-underscore-dangle */
import React, { useMemo, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Description,
} from './styled-components';
import FormInput from '../form-input';

function FormItem({ formState, step }) {
  const { formState: RHFFormState, setValue } = useFormContext();

  const error = useMemo(() => {
    const formError = RHFFormState.errors[step.id];

    if (formError) { return formError.message; }

    return null;
  }, [RHFFormState, step]);

  useEffect(() => {
    if (step.type === 'range') {
      setValue(step.min.id, formState[step.min.id] || step.min.value, { shouldValidate: true });
      setValue(step.max.id, formState[step.max.id] || step.max.value, { shouldValidate: true });
    }

    if (formState[step.id]) {
      setValue(step.id, formState[step.id], { shouldValidate: step.type === 'radio_box' });
    }
  }, [formState, setValue, step]);

  return (
    <div className="input-group">
      <label htmlFor={step.id}>{step.label}</label>
      {step.description && <Description>{step.description}</Description>}
      <FormInput step={step} error={error} />
      <span className="input-error">{error}</span>
    </div>
  );
}
export default FormItem;
