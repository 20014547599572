import { styled } from 'styled-components';

export const ProgressBarContainer = styled.div`
  width: 1rem;
  height: 100%;
  border: 1px solid var(--grey-400);
  border-radius: 1.25rem;

  @media only screen and (max-width: 1200px) {
    height: 1rem;
    width: 100%;
  }
`;

export const ProgressBarFill = styled.div`
  width: 100%;
  background: linear-gradient(180deg, #ffa0ea 0%, rgba(96, 65, 255, 0.28) 100%);
  border-radius: 1.25rem;
  height: ${(props) => props.percentage}%;
  transition: height 0.5s ease-in-out;
  @media only screen and (max-width: 1200px) {
    height: 100%;
    background: linear-gradient(90deg, #ffa0ea 0%, rgba(96, 65, 255, 0.28) 100%);
    border-radius: 1.25rem;
    width: ${(props) => (props.percentage > 0 ? props.percentage : 0)}%;
    transition: width 0.5s ease-in-out;
  }
`;

export const ProgressBarSection = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  flex-direction: column;
  @media only screen and (max-width: 1200px) {
    width: 100%;
    flex-direction: row;
  }
`;

export const Number = styled.div`
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--pink);
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;

  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;
