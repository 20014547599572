import React from 'react';
import { StyledThumb, StyledTrack, StyledSlider } from './styled-components';

function Thumb(props) {
  return <StyledThumb {...props} />;
}

function Track(props, { index }) {
  return <StyledTrack {...props} index={index} />;
}

function InputRange({
  min,
  max,
  value,
  onChange,
}) {
  return (
    <StyledSlider
      renderTrack={Track}
      renderThumb={Thumb}
      onChange={onChange}
      value={value}
      max={max}
      min={min}
    />
  );
}

export default InputRange;
