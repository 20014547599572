import { styled, keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;
`;

export const Title = styled.div`
  font-size: 4rem;
  font-weight: 500;
    line-height: 5rem;
  span {
    background: linear-gradient(90deg, var(--purple) 0%, var(--pink) 120%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media only screen and (max-width: 768px) {
    font-size: 2.5rem;
    line-height: 3rem;
  }

`;

export const Comment = styled.div`
  font-size: 2rem;
  line-height: 2rem;
  border-radius: 1rem;
  background: linear-gradient(90deg, transparent 0%, var(--black-900) 10%, var(--purple-300) 100%);
  animation: ${fadeIn} 0.5s;
  opacity: ${({ show }) => (show ? '1' : '0')};
  transition: opacity 0.5s;
  text-align: center;
  padding: 0.75rem 1.25rem;
  @media only screen and (max-width: 768px) {
    font-size: 1.25rem;
    line-height: 1.25rem;
  }
`;
