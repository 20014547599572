import React, { useEffect, useState } from 'react';
import { Title, LoadingContainer, Comment } from './styled-components';
import { rocket } from '../../assets/images';
import FormLayout from '../../components/form-layout';
import { resultText } from '../../constants/result';

function Loading() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showText, setShowText] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowText(false);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % resultText.length);
        setShowText(true);
      }, 500);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <FormLayout isLoading>
      <LoadingContainer>
        <Title>
          Prêt à (bientôt)
          {' '}
          <span>atterrir</span>
          {' '}
          ?
        </Title>
        <img src={rocket} alt="rocket" />
        <Comment show={showText ? 1 : 0}>{resultText[currentIndex]}</Comment>
      </LoadingContainer>
    </FormLayout>
  );
}

export default Loading;
