import React, { useMemo } from 'react';
import { findIndex } from 'lodash';
import { logo, dcVector } from '../../assets/images';
import {
  ContentContainer, Logo, MainContainer, Vector, ProgressBarContainer,
} from './styled-components';
import ProgressBar from '../progress-bar';
import form from '../../constants/form.json';

function FormLayout({ children, currentStep, isLoading = false }) {
  const currentStepProgress = useMemo(() => findIndex(form, (item) => item.id === currentStep?.id), [currentStep?.id]);
  const stepsProgress1 = findIndex(form, (item) => item.id === 'empathy_interstitial');
  const stepsProgress2 = form.length - stepsProgress1;

  return (
    <MainContainer>
      <Logo alt="Logo" src={logo} />
      <ContentContainer>
        {!isLoading && <Vector src={dcVector} />}
        <ProgressBarContainer>
          <ProgressBar currentStep={isLoading ? stepsProgress1 : currentStepProgress} steps={stepsProgress1} number="01" />
          <ProgressBar currentStep={isLoading ? stepsProgress2 : currentStepProgress - stepsProgress1} steps={stepsProgress2} number="02" />
        </ProgressBarContainer>
        {children}
      </ContentContainer>
    </MainContainer>
  );
}

export default FormLayout;
