/* eslint-disable no-nested-ternary */
import ReactSlider from 'react-slider';
import styled from 'styled-components';

export const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 4px;
  margin: 4rem 0;
  border-radius: 1.25rem;

  @media only screen and (max-width: 425px) {
    margin: 2rem 0;

  }
`;

export const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  background: ${(props) => (props.index === 2
    ? 'var(--grey-light)'
    : props.index === 1
      ? 'linear-gradient(270deg, #FFA0EA -50%, rgba(96, 65, 255, 0.28) 100%)'
      : '#949698')};
  border-radius: 50%;
`;

export const StyledThumb = styled.div`
  height: 2.5rem;
  width: 2.5rem;
  text-align: center;
  background-color: var(--grey-300);
  border-radius: 50%;
  cursor: grab;
  bottom: -1rem;

  @media only screen and (max-width: 768px) {
    height: 1.25rem;
    width: 1.25rem;
    bottom: -0.625rem;
  }
`;
