import React from 'react';
import { ResultsContainer } from '../../components';
import ResultsLayout from '../../components/results-layout';

function Results({ results }) {
  return (
    <ResultsLayout>
      <ResultsContainer results={results} />
    </ResultsLayout>
  );
}

export default Results;
