import { styled } from 'styled-components';

export const MainContainer = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;

  background: radial-gradient(
    99.37% 91.33% at 104.07% 0%,
    rgba(255, 160, 234, 0.13) 0%,
    rgba(22, 201, 238, 0.13) 33.33%,
    rgba(131, 105, 255, 0.11) 66.67%,
    rgba(23, 23, 23, 0.2) 100%
  );
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 26rem;
  width: 100%;
  flex: 1;
  box-sizing: border-box;

  @media only screen and (max-width: 1440px) {
    padding: 0 18rem;
  }
  @media only screen and (max-width: 1200px) {
    padding: 0 6rem;
  }
  @media only screen and (max-width: 768px) {
    padding: 0 4rem;
  }
  @media only screen and (max-width: 425px) {
    padding: 0 2rem;
    justify-content: flex-start;
    margin-top: 6rem;
  }
  

  button {
    align-self: center;
  }
`;

export const Logo = styled.img`
  height: 2rem;
  margin-top: 2rem;
`;

export const Vector = styled.img`
  position: absolute;
  bottom: 0vh;
  right: 10vw;
  height: auto;
  width: 24rem;
  @media only screen and (max-width: 1200px) {
    right: 0;
    width: 16rem;
  }
  @media only screen and (max-width: 768px) {
    width: 12rem;
  }
`;
