import React from 'react';
import {
  RadioContainer, RadioLabel, RadioInput, RadioButton, RadioButtonContainer,
} from './styled-components';

function RadioBox({
  options, value, onChange,
}) {
  return (
    <RadioContainer>
      {options.map((option) => (
        <RadioLabel key={option.value}>
          <RadioInput
            autoFocus
            type="radio"
            value={option.value}
            id={option.value}
            checked={value === option.value}
            onChange={() => onChange(option.value)}

          />
          <RadioButtonContainer>
            <RadioButton checked={value === option.value} />
          </RadioButtonContainer>
          {option.label}
        </RadioLabel>
      ))}
    </RadioContainer>
  );
}

export default RadioBox;
