import React from 'react';
import { logo, ArrowDc, ArrowDcMobile } from '../../assets/images';
import {
  ContentContainer,
  Logo,
  MainContainer,
  ButtonReturn,
  Arrow,
  ArrowMobile,
} from './styled-components';

function ResultsLayout({ children }) {
  return (
    <MainContainer>
      <Logo alt="Logo" src={logo} />
      <Arrow src={ArrowDc} />
      <ContentContainer>
        <h2>Merci de nous faire confiance</h2>
        <p>
          Comme vous le savez, chaque entreprise est unique et nécessite une approche sur mesure
          pour atteindre ses objectifs commerciaux spécifiques tout en tenant compte de son budget.
          Nous sommes là pour vous aider à naviguer dans le paysage publicitaire en ligne et à
          choisir les canaux les plus adaptés à vos besoins.
        </p>
        <p>
          Il est important de souligner que ces résultats sont des estimations basées sur des
          données et des expériences antérieures, mais elles peuvent varier en fonction de votre
          situation spécifique. Pour obtenir des résultats précis et maximiser l&apos;efficacité de
          votre budget, nous vous recommandons de travailler en étroite collaboration avec notre
          équipe d&apos;experts en marketing pour optimiser vos campagnes et ajuster votre stratégie
          en fonction des performances réelles.
        </p>
        <p>Cordialement,</p>
        <p>L&apos;équipe marketing</p>
        {children}
        <ButtonReturn href="/">Retour au site principal</ButtonReturn>
      </ContentContainer>
      <ArrowMobile>
        <img src={ArrowDcMobile} alt="arrow-dc" />
      </ArrowMobile>
    </MainContainer>
  );
}

export default ResultsLayout;
