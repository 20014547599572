import { styled } from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 2rem;
  gap: 1.5rem;
  border-radius: 0.25rem;
  background: var(--purple-light);
  backdrop-filter: blur(22px);
  box-sizing: border-box;

  @media only screen and (max-width: 768px) {
    padding: 0.75rem;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 1.5rem;
  position: relative;

  .btn {
    margin: 0;
    white-space: nowrap;
  }
  @media only screen and (max-width: 768px) {
    .btn {
      text-align: center;
      padding: 0.25rem 0.75rem !important;
      font-size: 0.75rem;
      white-space: normal;
    }
  }
`;

export const TextContainer = styled.div`
  width: 100%;
`;

export const Star = styled.img`
  position: absolute;
  top: 0;
  right: -0.375rem;

  @media only screen and (max-width: 768px) {
    top: -0.375rem;
    width: 0.75rem;
  }
`;

export const Text1 = styled.div`
  font-size: 2rem;
  font-weight: 700;
  position: relative;
  line-height: 2.5rem;

  @media only screen and (max-width: 768px) {
    line-height: normal;
    font-size: 0.75rem;
  }
`;

export const Text2 = styled.div`
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 2rem;

  @media only screen and (max-width: 768px) {
    font-size: 0.625rem;
    line-height: normal;
  }
`;
