import { styled } from 'styled-components';

export const RangeInputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 2rem 1.5rem;
  gap: 1.5rem;
  width: 100%;
  box-sizing: border-box;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border: 1px solid transparent;
    border-radius: 0.75rem;
    background: linear-gradient(90deg, rgba(255, 160, 234, 0.71) 20%, rgba(96, 65, 255, 0.18) 70%)
      border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  @media only screen and (max-width: 425px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

export const RangeInput = styled.div`
  position: relative;
  width: 100%;
`;

export const RangeInputLabel = styled.div`
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  font-size: 1.5rem;
  @media only screen and (max-width: 768px) {
    font-size: 1rem;
  }
`;
