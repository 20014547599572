import React from 'react';
import {
  filter, find, includes, map,
} from 'lodash';
import Select from 'react-select';
import { Controller, useFormContext } from 'react-hook-form';
import RadioBox from '../radio-box';
import InputRange from '../input-range';
import {
  RangeInputContainer, RangeInputLabel, RangeInput,
} from './styled-components';

function FormInput({
  step,
  error,
}) {
  const {
    register, control, setValue, watch,
  } = useFormContext();

  let validation = { required: 'Requis' };

  if (step.type === 'single_select') {
    return (
      <Controller
        control={control}
        name={step.id}
        rules={validation}
        render={({ field }) => (
          <Select
            {...field}
            key={step.id}
            className="select"
            classNamePrefix="select"
            placeholder={step.placeholder}
            autoFocus
            options={step.options}
            onChange={(data) => field.onChange(data.value)}
            value={find(step.options, { value: field.value })}
          />
        )}
      />
    );
  }
  if (step.type === 'range') {
    const maxValue = parseInt(watch(step.max.id) || 80, 10);
    const minValue = parseInt(watch(step.min.id) || 18, 10);

    return (
      <>
        <InputRange
          value={[minValue, maxValue]}
          min={18}
          max={100}
          step={1}
          onChange={([newMin, newMax]) => {
            setValue(step.min.id, newMin.toString(), { shouldValidate: true });
            setValue(step.max.id, newMax.toString(), { shouldValidate: true });
          }}
        />
        <RangeInputContainer>

          <Controller
            control={control}
            name={step.min.id}
            rules={validation}
            render={({ field }) => (
              <RangeInput>
                <input
                  type="number"
                  autoFocus
                  min={step.min.value}
                  max={step.max.value}
                  className={error ? 'with-error' : ''}
                  id={step.min.id}
                  onChange={field.onChange}
                  value={field.value || ''}
                  placeholder={step.min.placeholder}
                  {...register(step.min.id, validation)}
                />
                <RangeInputLabel>ans</RangeInputLabel>
              </RangeInput>
            )}
          />
          <Controller
            control={control}
            name={step.max.id}
            rules={validation}
            render={({ field }) => (
              <RangeInput>
                <input
                  type="number"
                  className={error ? 'with-error' : ''}
                  id={step.max.id}
                  min={step.min.value}
                  max={step.max.value}
                  onChange={field.onChange}
                  value={field.value || ''}
                  placeholder={step.max.placeholder}
                  {...register(step.max.id, validation)}
                />
                <RangeInputLabel>ans</RangeInputLabel>
              </RangeInput>

            )}
          />
        </RangeInputContainer>

      </>
    );
  }

  if (step.type === 'radio_box') {
    return (
      <Controller
        control={control}
        name={step.id}
        rules={validation}
        render={({ field }) => (
          <RadioBox
            key={step.id}
            options={step.options}
            onChange={field.onChange}
            value={field.value}
          />
        )}
      />
    );
  }

  if (step.type === 'multi_select') {
    return (
      <Controller
        control={control}
        name={step.id}
        placeholder={step.placeholder}
        rules={validation}
        render={({ field }) => (
          <Select
            {...field}
            key={step.id}
            className="select"
            classNamePrefix="select"
            isMulti
            autoFocus
            options={step.options}
            onChange={(data) => field.onChange(map(data, 'value'))}
            value={filter(step.options, (option) => includes(field.value, option.value))}
          />
        )}
      />
    );
  }

  let type = 'text';
  switch (step.type) {
    case 'text':
      break;
    case 'email':
      validation = {
        ...validation,
        pattern: {
          value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
          message: 'N\'est pas valide',
        },
      };
      break;
    case 'url':
      validation = {
        ...validation,
        pattern: {
          value: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
          message: 'N\'est pas valide',
        },
      };
      break;
    case 'number':
      type = 'number';
      break;
    case 'number_euro':
      type = 'number';
      break;
    default:
      throw new Error(`Type ${step.type} is not handled yet`);
  }

  return (
    <Controller
      control={control}
      name={step.id}
      rules={validation}
      render={({ field }) => (
        <input
          autoCapitalize="on"
          autoFocus
          type={type}
          className={error ? 'with-error' : ''}
          id={step.id}
          onChange={field.onChange}
          value={field.value || ''}
          placeholder={step.placeholder}
          {...register(step.id, validation)}
        />
      )}
    />

  );
}

export default FormInput;
