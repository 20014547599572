import React from 'react';
import { logo, dcVector } from '../../assets/images';
import {
  ContentContainer, Vector, Logo, MainContainer,
} from './styled-components';

function InterstitialLayout({ children }) {
  return (
    <MainContainer>
      <Logo alt="Logo" src={logo} />
      <Vector src={dcVector} />
      <ContentContainer>{children}</ContentContainer>
    </MainContainer>
  );
}

export default InterstitialLayout;
