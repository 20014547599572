import { styled } from 'styled-components';

export const RadioContainer = styled.div`
  display: flex;
  gap: 2rem;
  width: 100%;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const RadioLabel = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 400;
  line-height: 1.5rem;
  padding: 2rem 1.5rem;
  width: 100%;
  font-size: 1.5rem;
  box-sizing: border-box;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border: 1px solid transparent;
    border-radius: 0.75rem;
    background: linear-gradient(90deg, rgba(255, 160, 234, 0.71) 20%, rgba(96, 65, 255, 0.18) 70%)
      border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  @media only screen and (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const RadioInput = styled.input`
  display: none;
`;

export const RadioButtonContainer = styled.div`
  width: 3rem;
`;

export const RadioButton = styled.div`
  position: relative;
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  border: 3px solid #ffffff;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    z-index: -1;
    background-color: var(--purple);
    opacity: ${({ checked }) => (checked ? '1' : '0')};
  }
`;
