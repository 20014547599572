/* eslint-disable react/no-danger */
import React, { useCallback } from 'react';
import { FormProvider } from 'react-hook-form';
import { FormItem } from '../../components';
import InterstitialLayout from '../../components/interstitial-layout';
import FormLayout from '../../components/form-layout';
import { textWithGradient } from '../../helpers';
import { ButtonContainer } from './styled-components';

function Form({
  formState, methods, currentStep, onSubmit, prevStep,
}) {
  const formContent = useCallback(() => {
    if (!currentStep.type) {
      return (
        <InterstitialLayout>
          {currentStep.title && <h1 dangerouslySetInnerHTML={{ __html: textWithGradient(currentStep.title) }} />}
          {currentStep.subtitle && <h2 dangerouslySetInnerHTML={{ __html: currentStep.subtitle }} />}
          {currentStep.description && <p>{currentStep.description}</p>}
          <ButtonContainer>
            { currentStep.id !== 'welcome' && <button onClick={prevStep} type="button">Précédent</button>}
            <button type="submit">{currentStep.id === 'welcome' ? 'Démarrer' : 'Suivant'}</button>
          </ButtonContainer>
        </InterstitialLayout>
      );
    }

    return (
      <FormLayout currentStep={currentStep}>
        {currentStep.title && <h1 dangerouslySetInnerHTML={{ __html: textWithGradient(currentStep.title) }} />}
        {currentStep.subtitle && <h2 dangerouslySetInnerHTML={{ __html: currentStep.subtitle }} />}
        <FormItem step={currentStep} formState={formState} />
        <ButtonContainer>
          { currentStep.id !== 'name' && <button onClick={prevStep} type="button">Précédent</button>}
          <button disabled={!methods.formState.isValid} type="submit">Suivant</button>
        </ButtonContainer>
      </FormLayout>
    );
  }, [currentStep, formState, methods.formState.isValid, prevStep]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {formContent()}
      </form>
    </FormProvider>
  );
}

export default Form;
