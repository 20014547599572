import React from 'react';
import ResultContainer from '../result-container';
import {
  MainContainer, FooterContainer, Text1, Text2, Star, TextContainer,
} from './styled-components';

import { star } from '../../assets/images';

function ResultsContainer({ results }) {
  return (
    <MainContainer>
      {results.googleMetrics && <ResultContainer service="googleAds" result={results.googleMetrics} />}
      {results.facebookMetrics && <ResultContainer service="facebookAds" result={results.facebookMetrics} />}
      {results.linkedinMetrics && <ResultContainer service="linkedinAds" result={results.linkedinMetrics} />}
      <FooterContainer>
        <TextContainer>
          <Text1>
            Paré au décollage ?
            <span>
              <Star src={star} />
            </span>
          </Text1>
          <Text2>On t’emmène dans un nouvel espace-temps du marketing.</Text2>
        </TextContainer>
        <a className="btn" href="https://derniercri.io/expertises/amplifier?ref=celeste">Être recontacté par l’équipe</a>
      </FooterContainer>
    </MainContainer>
  );
}

export default ResultsContainer;
