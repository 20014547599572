import React from 'react';
import {
  ProgressBarContainer, ProgressBarFill, ProgressBarSection, Number,
} from './styled-components';

function ProgressBar({
  currentStep, steps, number,
}) {
  const percentage = currentStep <= steps ? (currentStep / steps) * 100 : 100;

  return (
    <ProgressBarSection>
      <Number>{number}</Number>
      <ProgressBarContainer>
        <ProgressBarFill percentage={percentage} />
      </ProgressBarContainer>
    </ProgressBarSection>
  );
}

export default ProgressBar;
