import {
  find, get, head, isArray, isEmpty,
} from 'lodash';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import './App.css';
import form from './constants/form.json';
import conversionRates from './constants/conversion-rates.json';
import { Form, Results, Loading } from './screens';

function App() {
  const [currentStep, setCurrentStep] = useState(form.find((item) => item.initial));
  const [formState, setFormState] = useState({});
  const [results, setResults] = useState();
  const [isLoading, setLoading] = useState();
  const methods = useForm();

  const previousSteps = useMemo(() => [], []);

  const nextStep = useCallback((submittedValue) => {
    setCurrentStep((actualCurrentStep) => {
      if (!actualCurrentStep?.next) {
        return find(form, { id: get(find(actualCurrentStep?.options, (option) => option.value === submittedValue), 'next') });
      }
      if (actualCurrentStep?.next === 'b2b_check') {
        return find(form, { b2b: formState.sector === 'b2b' });
      }
      return find(form, { id: actualCurrentStep?.next });
    });
  }, [formState]);

  const prevStep = useCallback(() => {
    setCurrentStep(previousSteps.pop());
  }, [previousSteps]);

  const fieldSubmitListener = useCallback((props) => {
    const { sector } = props;
    if (sector) {
      setFormState((oldFormState) => ({
        ...oldFormState,
        conversion_rate: conversionRates[sector],
      }));
    }
  }, [setFormState]);

  const onSubmit = useCallback((data) => {
    setFormState((currentFormState) => ({
      ...currentFormState,
      ...data,
    }));
    fieldSubmitListener(data);
    const newValue = (data[currentStep.id]);

    previousSteps.push(currentStep);
    nextStep(isArray(newValue) ? head(newValue) : newValue);
    methods.reset();
  }, [currentStep, methods, nextStep, previousSteps, fieldSubmitListener]);

  const requestAnalysis = useCallback(async (state) => {
    setLoading(true);
    try {
      const response = await fetch(process.env.REACT_APP_FUNCTION_URL, {
        method: 'POST',
        body: JSON.stringify(state),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const newResults = await response.json();
      setResults(newResults);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  }, [setLoading, setResults]);

  useEffect(() => {
    if (isEmpty(currentStep) && !isEmpty(formState)) {
      requestAnalysis(formState);
    }
  }, [currentStep, formState, requestAnalysis]);

  if (isLoading) {
    return <Loading />;
  }

  if (results) {
    return <Results results={results} />;
  }

  if (currentStep) {
    return (
      <Form
        formState={formState}
        currentStep={currentStep}
        methods={methods}
        prevStep={prevStep}
        onSubmit={onSubmit}
      />
    );
  }

  return <h1>Félicitations c&apos;est tout bon :D</h1>;
}

export default App;
