import { styled } from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  gap: 2.5rem;
  width: 100%;
  justify-content: center;

  @media only screen and (max-width: 425px) {
    gap: 0.75rem;

  }
`;
