import { styled } from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  background: var(--black-900);
  border-radius: 0.25rem;
  flex-direction: column;
  padding: 1.5rem 0 1.5rem 2.5rem;
  color: white;
  gap: 2.5rem;

  h2 {
    color: #6041ff;
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  h3 {
    color: white;
    font-size: 1.5rem;
    font-weight: 400;
    padding: 0.5rem 1.25rem;
    background-color: var(--purple);

    span {
      color: white;
      font-size: 1.5rem;
      font-weight: 700;
    }
  }
  h4 {
    color: white;
    font-size: 1rem;
    font-weight: 400;
  }

  h5 {
    color: white;
    font-size: 1.5rem;
    font-weight: 700;
  }

  @media only screen and (max-width: 768px) {
    padding: 0.5rem 0 0.5rem 0.75rem;
    gap: 0.75rem;
    h3 {
      font-size: 0.75rem !important;
      padding: 0 0.5rem;
    }
    span {
      font-size: 1rem !important;
    }
    h5 {
      font-size: 1rem;
    }
    h4 {
      font-size: 0.625rem;
    }
  }

  @media only screen and (max-width: 320px) {
  
    h5 {
      font-size: 0.75rem;
    }
    h4 {
      font-size: 0.5rem;
    }
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const StatsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding-right: 2.5rem;
  box-sizing: border-box;
  span {
    font-size: 0.75rem;
    color: var(--grey-light);
    margin-left: 0.5rem;
    font-weight: bold;
    cursor: default;
  }
  @media only screen and (max-width: 768px) {
    padding-right: 0.75rem;
    span{
    font-size: 0.5rem !important;
    margin-left: 0.25rem;

    }
  }
`;

export const Stat = styled.div`
  align-items: center;
  border-right: 1px solid white;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  justify-content: space-between;
  padding: 0 1rem;
  text-align: center;
  @media only screen and (max-width: 768px) {
    padding: 0 0.25rem;
    gap: 0;
  }
  &:last-child {
    border-right: none;
  }
`;

export const ServiceImage = styled.img`
  @media only screen and (max-width: 768px) {
    height: auto;
    width: 4rem;
  }
`;

export const TooltipTitle = styled.div`
  font-size: 0.75rem;
  text-align: start;
  font-weight: 700;
  line-height: 1rem;
  @media only screen and (max-width: 768px) {
  }
`;

export const TooltipDesc = styled.div`
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: start;
  @media only screen and (max-width: 768px) {
  }
`;
