import React, { useMemo } from 'react';
import { Tooltip } from 'react-tooltip';
import {
  HeaderContainer,
  MainContainer,
  Stat,
  StatsContainer,
  ServiceImage,
  TooltipTitle,
  TooltipDesc,
} from './styled-components';
import { googleAds, linkedinAds, facebookAds } from '../../assets/images';
import tooltip from '../../constants/tooltip.json';

const servicesLogo = {
  googleAds,
  facebookAds,
  linkedinAds,
};

function ResultContainer({ service, result }) {
  const budget = useMemo(
    () => Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(result.budget),
    [result.budget],
  );

  const cac = useMemo(
    () => Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(result.cac),
    [result.cac],
  );

  return (
    <MainContainer>
      <HeaderContainer>
        <ServiceImage src={servicesLogo[service]} alt={service} />
        <h3>
          Budget &nbsp;
          <span>{budget}</span>
        </h3>
      </HeaderContainer>
      <StatsContainer>
        <Stat>
          <h5>{result.impressions}</h5>
          <h4>
            Impressions
            <span data-tooltip-id="impressions">&#9432;</span>
          </h4>
          <Tooltip id="impressions">
            <TooltipTitle>{tooltip[service].impressions.title}</TooltipTitle>
            <TooltipDesc>{tooltip[service].impressions.description}</TooltipDesc>
          </Tooltip>
        </Stat>
        <Stat>
          <h5>{result.clicks}</h5>
          <h4>
            Visites
            <span data-tooltip-id="visits">&#9432;</span>
          </h4>
          <Tooltip id="visits">
            <TooltipTitle>{tooltip[service].visits.title}</TooltipTitle>
            <TooltipDesc>{tooltip[service].visits.description}</TooltipDesc>
          </Tooltip>
        </Stat>
        <Stat>
          <h5>{result.conversions}</h5>
          <h4>
            Conversions
            <span data-tooltip-id="conversions">&#9432;</span>
          </h4>
          <Tooltip id="conversions">
            <TooltipTitle>{tooltip[service].conversions.title}</TooltipTitle>
            <TooltipDesc>{tooltip[service].conversions.description}</TooltipDesc>
          </Tooltip>
        </Stat>
        <Stat>
          <h5>{cac}</h5>
          <h4>
            CAC
            <span data-tooltip-id="cac">&#9432;</span>
          </h4>
          <Tooltip id="cac">
            <TooltipTitle>{tooltip[service].cac.title}</TooltipTitle>
            <TooltipDesc>{tooltip[service].cac.description}</TooltipDesc>
          </Tooltip>
        </Stat>
      </StatsContainer>
    </MainContainer>
  );
}

export default ResultContainer;
