import { styled } from 'styled-components';

export const MainContainer = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4rem;
  align-items: center;
  height: 100vh;
  background: radial-gradient(
    99.37% 91.33% at 104.07% 0%,
    rgba(255, 160, 234, 0.13) 0%,
    rgba(22, 201, 238, 0.13) 33.33%,
    rgba(131, 105, 255, 0.11) 66.67%,
    rgba(23, 23, 23, 0.2) 100%
  );

  @media only screen and (max-width: 1200px) {
    gap: 0;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0 26rem;
  gap: 1rem;
  flex: 1;
  z-index: 1;
  box-sizing: border-box;

  p {
    border: 0;
    font-size: 1.5rem;
    line-height: 2rem;
    padding: 0;
    margin: 0;
  }

  @media only screen and (max-width: 1440px) {
    padding: 0 18rem;
  }
  @media only screen and (max-width: 1200px) {
    padding: 0 6rem;
    width: 100%;
  }
  @media only screen and (max-width: 768px) {
    padding: 4rem;
    p {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  @media only screen and (max-width: 425px) {
    padding: 2rem;
  }

  button {
    align-self: center;
  }
`;

export const Logo = styled.img`
  height: 2rem;
  margin-top: 2rem;
`;

export const ButtonReturn = styled.a`
  width: 100%;
  color: var(--white);
  text-align: center;
  font-size: 1.25rem;
  margin-bottom: 2rem;
  @media only screen and (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const Arrow = styled.img`
  position: absolute;
  left: 0;
  bottom: 30%;
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;

export const ArrowMobile = styled.div`
  display: none;
  @media only screen and (max-width: 1200px) {
    width: 100%;
    display: flex;
    justify-content: end;
  }
`;
